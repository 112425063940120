import {
  validateRequired,
  Validator,
} from 'application/utils/validators';

// eslint-disable-next-line max-len
export const SUBMIT_FORM_TRACK_SHIPMENT = '[form] submit form track shipment';
export type SubmitForm = {
  type: typeof SUBMIT_FORM_TRACK_SHIPMENT;
}
export const submitForm = {
  type: SUBMIT_FORM_TRACK_SHIPMENT,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_TRACK_SHIPMENT = '[forms] set loading track shipment';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_TRACK_SHIPMENT;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_TRACK_SHIPMENT,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_TRACK_SHIPMENT = '[forms] set success track shipment';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_TRACK_SHIPMENT;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_TRACK_SHIPMENT,
  payload: {
    isSuccess,
  },
});

// eslint-disable-next-line max-len
export const SET_INPUT_TRACK_SHIPMENT = '[forms] input change track shipment';

export type SetInput = {
  type: typeof SET_INPUT_TRACK_SHIPMENT;
  payload: {
    fieldName: string;
    value: string;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_TRACK_SHIPMENT;
  payload: {
    [fieldName: string]: {
      value: string;
      error: string;
    }
  }
}

export const setRequiredInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_TRACK_SHIPMENT,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});

// eslint-disable-next-line max-len
export const SET_GENERAL_ERROR_TRACK_SHIPMENT = 'set general error track shipment';
export type SetGeneralErrorMessage = {
  type: typeof SET_GENERAL_ERROR_TRACK_SHIPMENT;
  payload: string;
}

export const setGeneralErrorMessage = (
  message: SetGeneralErrorMessage['payload'],
): SetGeneralErrorMessage => ({
  type: SET_GENERAL_ERROR_TRACK_SHIPMENT,
  payload: message,
});

export const RESET_FORM_TRACK_SHIPMENT = '[track-shipment] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_TRACK_SHIPMENT,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_TRACK_SHIPMENT,
};

export type TrackShipmentActions =
| SetInputValidated
| SetFormLoading
| SetFormSuccess
| SetGeneralErrorMessage
| ResetForm;
