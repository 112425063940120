import { Middleware } from 'redux';
import { Services } from 'src/application/interfaces';
import { Store } from 'src/application/middleware';
import { validateRequired } from 'application/utils/validators';
import {
  setFormLoading,
  setFormSuccess,
  setGeneralErrorMessage,
  SetInput,
  SetInputValidated,
  setRequiredInput,
  SET_INPUT_TRACK_SHIPMENT,
  SubmitForm,
  SUBMIT_FORM_TRACK_SHIPMENT,
} from './actions';

export const InputChanged = (
): Middleware => () => (next) => (action: SetInput): void => {
  if (action.type === SET_INPUT_TRACK_SHIPMENT) {
    const { payload } = action as SetInput;

    let error = '';
    payload.validators.forEach((validate) => {
      const errorCode = validate(payload.value);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetInputValidated['payload'] = {
      [payload.fieldName]: {
        value: payload.value,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const FormSubmitted = ({
  queryStringParameters,
}: Services): Middleware => ({
  dispatch,
  getState,
}: Store) => (next) => async (action: SubmitForm): Promise<void> => {
  next(action);

  if (action.type === SUBMIT_FORM_TRACK_SHIPMENT) {
    if (!queryStringParameters || !queryStringParameters.update) {
      // eslint-disable-next-line no-console
      console.error('no queryStringParameters service available');
      return;
    }

    dispatch(setFormLoading(true));

    const { trackShipment } = getState();

    if (!trackShipment) {
      throw new Error('Failed loading module');
    }

    const {
      numbers,
    } = trackShipment;

    let canSubmit = true;

    dispatch(setGeneralErrorMessage(''));
    if (validateRequired(numbers.value)) {
      dispatch(setRequiredInput('numbers')(numbers.value));
      canSubmit = false;
    }

    if (!canSubmit) {
      dispatch(setFormLoading(false));
      return;
    }

    dispatch(setFormSuccess(true));
    try {
      queryStringParameters.update('trackingno', numbers.value);
    } catch (error) {
      dispatch(setGeneralErrorMessage(
        error.description || error.message,
      ));
      // eslint-disable-next-line no-console
      console.error(error.message);
    }
    dispatch(setFormLoading(false));
  }
};

export default [
  InputChanged,
  FormSubmitted,
];
